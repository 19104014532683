<template lang="pug">
	ion-page
		ion-header
			ion-toolbar
				ion-buttons(slot='start')
					ion-back-button(default-href='' @click.prevent="$router.push({ name: 'Profile' })" text='')
				ion-title.ion-text-center.fs-big Zoznam skupín
		ion-content
			div(v-if='getGroupInvites.length || getJoinedGroups.length')
				ion-list.invites
					ion-item.invite(v-for='groupInvite in getGroupInvites' :key="groupInvite.group.id")
						div.d-flex.flex
							div(v-if='groupInvite.group.teacher')
								ion-label.invite-text.ion-text-wrap(
									position='stacked'
								) Uživateľ {{ groupInvite.group.teacher.name }} {{ groupInvite.group.teacher.surname }} vás chce pridať do skupiny:
								ion-label.fw-bold.invite-group(
									position='stacked'
								) {{ groupInvite.group.name }}
							div
								ion-button(
									color="primary" fill="outline"
									@click="joinGroup(groupInvite)"
								) Pridať sa do skupiny

				ion-list.groups
					ion-item.group(v-for='joinedGroup in getJoinedGroups' :key="joinedGroup.group.id")
						ion-label.fw-bold.group-name {{ joinedGroup.group.name }}
						ion-note.group-info.d-flex(slot='end')
							| {{ joinedGroup.group.members_count }} {{ membersText(joinedGroup.group.members_count)  }}
							//div.group-delete.d-flex.ion-margin-start
							//  div.group-delete-circle
							//    img.group-delete-icon(:src='require("@/assets/images/delete-icon.svg")')
			div(v-else)
				p Nie si v žiadnej skupine
				ion-icon.a-illustration(:src="require('@/plugins/app/_theme/assets/icon/emptylist.svg')")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Groups',
	computed: {
		...mapGetters('group', [ 'getGroupInvites', 'getJoinedGroups' ])
	},
	async mounted() {
		await this.$store.dispatch('group/fetchGroups')
	},
	methods: {
		async joinGroup(group) {
			await this.$store.dispatch('group/joinGroup', group.invite_hash)
		},

		membersText(count) {
			switch (count) {
			case 1: return 'užívateľ'
			case 2:
			case 3:
			case 4: return 'užívatelia'
			default: 'užívateľov'
			}
		}
	}
}
</script>

<style lang="sass" scoped>
ion-toolbar
	height: 56px
	margin-top: 24px

ion-header
	box-shadow: 5px 5px 5px rgba(247, 247, 249, 1)

.groups
	.group
		margin-top: 0
		--inner-padding-top: 0.5rem
		--inner-padding-bottom: 0.5rem
		--inner-padding-end: 0
		--padding-end: 0
		.group-name
			font-size: 24px
		.group-info
			margin: 0 1rem 0 0
			font-size: 16px
			align-content: center
			align-items: center
			.group-delete
				background-color: rgba(239,65,54,0.5)
				padding: 8px
				width: 80px
				height: 80px
				.group-delete-circle
					padding: 8px
					border: 1px solid #E6E7EE
					border-radius: 100%
					width: 36px
					height: 36px
					margin: auto
					.group-delete-icon
						width: 18px

.invites
	.invite
		--inner-padding-top: 1rem
		--inner-padding-bottom: 1rem
		.flex
			width: 100%
			align-content: center
			align-items: center
			justify-content: space-between
			.invite-text
				opacity: 0.5
				font-size: 16px
			.invite-group
				font-size: 18px
</style>
