import { render, staticRenderFns } from "./groups.vue?vue&type=template&id=b0c9d098&scoped=true&lang=pug&"
import script from "./groups.vue?vue&type=script&lang=js&"
export * from "./groups.vue?vue&type=script&lang=js&"
import style0 from "./groups.vue?vue&type=style&index=0&id=b0c9d098&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0c9d098",
  null
  
)

export default component.exports